import { suspensify } from "lib/utils/suspensify";
import { useEffect, useState } from "react";

export default function useMaintenance() {
  const [isMaintenanceModeOn, setIsMaintenanceModeOn] = useState(false);

  useEffect(() => {
    if (isMaintenanceModeOn) return;
    suspensify(
      fetch("/api/maintenance")
        .then((response) => response.json())
        .then((data) => setIsMaintenanceModeOn(data.maintenanceMode))
        .catch((error) => {
          setIsMaintenanceModeOn(true);
          throw error;
        })
    );
  }, [isMaintenanceModeOn, setIsMaintenanceModeOn]);

  return !!isMaintenanceModeOn;
}
