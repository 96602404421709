import { Spinner } from "@zapier/design-system";
import { styled } from "lib/theme";

const SpinnerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function BuilderPageSpinner({
  "data-testId": dataTestId,
}: {
  "data-testId"?: string;
}) {
  return (
    <SpinnerWrapper data-testid={dataTestId}>
      <Spinner size="medium" />
    </SpinnerWrapper>
  );
}
