import Maintenance from "./MaintenanceComponent";

type Props = {
  children: React.ReactNode;
  useMaintenanceHook: () => boolean;
};

export function MaintenancePage({ children, useMaintenanceHook }: Props) {
  const isMaintenanceModeOn = useMaintenanceHook();

  return isMaintenanceModeOn ? <Maintenance /> : <>{children}</>;
}
